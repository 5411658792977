import '../Styles/Instructions.css';
import assPickle from '../Assets/Ass_PICKLE.png';
import grabPickle from '../Assets/Grab_PICKLE.png';
import pengPlug from '../Assets/Peng_Plug.png';

const Card = ({number, img, title, desc}) => {
    <div className= "instructions-card">
        <p>{number}</p>
        <img src = {img} ></img>
        <p>{title}</p>
        <p>{desc}</p>
    </div>
}

const Instructions = () => {
    return (
        <>
            <section id= "instructions">
                <h3 id= 'instructions-title'>Instructions</h3>
                <p className='desc-section'>PICKLEISGOOD is a revolutionary drug infused with the mythical DNA of PEPE. 
                    This substance promises a unique experience, immersing its users in a world where humor, 
                    melancholy and deep reflection are unexpectedly intertwined</p>
                    <div id='container-steps'>
                    <div className= "instructions-card">
                        <p className='instructions-card-numb'>1</p>
                        <img src = {pengPlug} className='no-select' loading='lazy'></img>
                        <p className= "instructions-card-title">Find a Plug</p>
                        <p className= "instructions-card-desc">The plug is easy to recognize. It looks like PEPE but it's a peinguin</p>
                    </div>
                    <div className= "instructions-card">
                        <p className='instructions-card-numb'>2</p>
                        <img src = {grabPickle} className='no-select' loading='lazy'></img>
                        <p className= "instructions-card-title">Grab the PICKLEISGOOD</p>
                        <p className= "instructions-card-desc">Upon handling, it secretes the peculiar molecule Rectum-Instigatum, 
                        which prompts an involuntary physiological response compelling the user to administer the substance 
                        rectally, an impulse so overpowering it's utterly impossible to resist</p>
                    </div>
                    <div className= "instructions-card">
                        <p className='instructions-card-numb'>3</p>
                        <img src = {assPickle} className='no-select' loading='lazy'></img>
                        <p className= "instructions-card-title">Rectal insertion</p>
                        <p className= "instructions-card-desc">Let the powerful product penetrate your body. You'll be in a state where 
                        you can feel every emotion possible</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Instructions;