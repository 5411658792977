import '../Styles/Footer.css';
import websiteLogo from '../Assets/Logo_Website.png';

const Footer = ()=> {
    return (
        <>
            <footer>
                <div id='footer-container'>
                        <img src={websiteLogo} loading='lazy' id='footer-logo' className='no-select'></img>
                        <div id='container-footer-text'>
                            <p>DISCLAIMER</p>
                            <p>This story is fictional. Don't stick a pickle up your bum. Shoving a pickle up your bum won't make you high, 
                                it'll just make you weird.. huhh well I guess</p>
                        </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;