import '../Styles/Story.css';
import story01 from '../Assets/Story_01.png';
import story02 from '../Assets/Story_02.png';
import story03 from '../Assets/Story_03.png';

const Story = () => {
    return (
        <>
            <section id = "story">
                <h3 id='story-title'>Story</h3>
                <div id= "story-images">
                    <img src= {story01} loading= "lazy" className= "no-select"></img>
                    <img src= {story02} loading= "lazy" className= "no-select"></img>
                    <img src= {story03} loading= "lazy" className= "no-select"></img>
                </div>
            </section>
        </>
    )
}

export default Story;