import React, { createContext, useState, useContext, useEffect } from 'react';

export const FirstClickContext = createContext();
export const useFirstClick = () => useContext(FirstClickContext);

export const FirstClickProvider = ({ children }) => {
    const [firstClickDetected, setFirstClickDetected] = useState(false);

    useEffect(() => {
        const handleFirstClick = (event) => {
          if (!firstClickDetected) {
            console.log("First click (touch) detected:", event);
            setFirstClickDetected(true);
          }
        };
        window.addEventListener('touchstart', handleFirstClick);
    
        return () => {
          window.removeEventListener('touchstart', handleFirstClick);
        };
      }, [firstClickDetected]);

    return (
        <FirstClickContext.Provider value={{ firstClickDetected, setFirstClickDetected }}>
          {children}
        </FirstClickContext.Provider>
      );
    };