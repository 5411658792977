import '../Styles/Holders.css';
import drink from '../Assets/Drink_Promotion.png';
import avatarCustom from '../Assets/Customize_Avatar.png'
import React, { useState } from 'react';

function RandomImageGenerator() {
    const images = ['PICKLEISGOOD_Random01.png', 'PICKLEISGOOD_Random02.png',
     'PICKLEISGOOD_Random03.png', 'PICKLEISGOOD_Random04.png','PICKLEISGOOD_Random05.png', 'PICKLEISGOOD_Random06.png'];
  
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
  
    const generateRandomImage = () => {
        setLoading(true); // Start loading
        setTimeout(() => {
          const randomIndex = Math.floor(Math.random() * images.length);
          const randomImage = images[randomIndex];
          setCurrentImageUrl(`${process.env.PUBLIC_URL}/img/${randomImage}`);
          setLoading(false);
        }, 1000);
      };
    
      const downloadImage = async () => {
        if (!currentImageUrl) return;
        const response = await fetch(currentImageUrl);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = currentImageUrl.split('/').pop(); // Get the filename from the URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      };
    
      return (
        <div id='container-random-generation'>
            <div>
            <button onClick={generateRandomImage} className='btn-holders'>Generate Random Image</button>
            </div>
          
          {loading && <div className="spinner"></div>} 
          {!loading && currentImageUrl && (
            <div id='container-result-download'>
              <img src={currentImageUrl} alt="Random" id='image-generated-random' />
              <button onClick={downloadImage} className='btn-holders'>
                Download
              </button>
              
            </div>
          )}
        </div>
      );
  }

const Holders = ()=> {
    return (
        <>
            <div id='holders-section'>
                <h3 id='holders-title'>Holders</h3>
                <p className='desc-section'> For the first time in the history of animated series, digital token holders will be able to 
                collaborate closely in the creation of the series. The holders of <strong>$PICKLEISGOOD</strong> will be able to 
                create their own avatars, logos, scripts and much more to come</p>

                <div id='container-holders-possibility'>
                    <div className='card-holders'>
                        <img src={drink} loading='lazy' className='no-select'></img>
                        <div className='card-holders-text'>
                            <p className='card-holders-text-title'>ADD YOUR OWN LOGO</p>
                            <p>The best holders will be able to put their logo or message on different products so that 
                                they can promote what they want for all viewers to see</p>
                            <p id='card-holders-text-desc-last'>Holders with the most tokens will have their logos displayed over several seasons</p>
                        </div>                        
                    </div>

                    <div className='card-holders card-holders-reversed'>                    
                        <img src={avatarCustom} loading='lazy' className='no-select'></img>                        
                        <div className='card-holders-text'>
                            <p className='card-holders-text-title'>CUSTOMIZE YOUR OWN PICKLEISGOOD</p>
                            <p>Holders will be able to create their own PICKLEISGOOD looks with rarer items for larger holders</p>
                            <p id='card-holders-text-desc-last'>The community will vote for the best looks and the looks with the most votes will be displayed in the series, 
                            and the creators of the best looks will receive <strong>$PICKLEISGOOD</strong> <strong></strong></p>
                        </div>
                    </div>
                </div>

                <div id='random-generation'>
                <p id='call-to-action-generate-random'>Try to generate a random PICKLEISGOOD and download it to show everyone !</p>
                    <RandomImageGenerator/>                    
                </div>

            </div>
        </>
    )
}

export default Holders;