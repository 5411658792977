import { Component } from 'react';
import websiteLogo from '../Assets/Logo_Website.png';
import '../Styles/Navbar.css';
import {isMobile} from 'react-device-detect';
import { FirstClickContext } from './FirstClickContext';

class Navbar extends Component {
    state = { clicked: false };

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }
    render() {
        return (
            <FirstClickContext.Consumer>
                {({ firstClickDetected }) => (
                    <>
                        <nav style={{pointerEvents: isMobile ? (firstClickDetected ? 'auto' : 'none') : 'auto'}}>
                            <a href='index.html'><img src= {websiteLogo} className='no-select'></img></a>
                            <div>
                                <ul id='navbar' className={this.state.clicked ? "#navabar active" : "#navbar"}>
                                    <li><a href='#story'>Story</a></li>
                                    <li><a href='#instructions'>Instructions</a></li>
                                    <li><a href='#holders-section'>Holders</a></li>
                                    <li><a href='#tokenomics-section'>Tokenomics</a></li>
                                </ul>
                            </div>
            
                            <div id='mobile' onClick={this.handleClick}>
                                <i id="bar" className=
                                {this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                            </div>
                        </nav>
                    </>
                )}
            </FirstClickContext.Consumer>
        ) 
    }
}

export default Navbar;