import '../Styles/Tokenomics.css';

const Tokenomics = ()=>  {
    return (
        <>
            <section id='tokenomics-section'>
                <h3 id='tokenomics-title'>Tokenomics</h3>
                <div id='wrapper-contract'>
                    <div id= "container-contract">
                        <p>Contract:</p>
                        <p>FKaUMenRcVWhJ6Qa7yRxcSqkqfg5Bzok95V2AUdjCfTj</p>
                    </div>
                </div>
                
                <div id="contract-information">
                    <div className='wrapper-card border-color1'>
                        <div className="tokenomics-card">
                            <p>TAXES</p>
                            <p>0/0</p>
                        </div>
                    </div>
                    
                    <div className='wrapper-card border-color1'>
                        <div className="tokenomics-card">
                            <p>MINT & FREEZE</p>
                            <p>REVOKED</p>
                        </div>
                    </div>
                    
                    <div className='wrapper-card border-color1'>
                        
                        <div className="tokenomics-card">
                            <p>LIQUIDITY</p>
                            <p>BURNED</p>
                        </div>
                    </div>

                    <div className='wrapper-card border-color1'>
                        <div className="tokenomics-card">
                            <p>TOTAL SUPPLY</p>
                            <p>1,000,000,000</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tokenomics;