import '../Styles/LandingPage.css';
import landingPageBackground from '../Assets/LandingPage_Background_Img.png';
import landingPageBackgroundMobile from '../Assets/LandingPage_Background_Img_Mobile.png';
import twitterLogo from '../Assets/X_Logo.svg';
import telegramLogo from '../Assets/Telegram_Logo.svg';
import dexscreenerLogo from '../Assets/Dexscreener_Logo.png';
import bobby from '../Assets/Bobby.png';
import miniPickle from '../Assets/Mini_PICKLEISGOOD.png';
import {isMobile} from 'react-device-detect';
import { useFirstClick } from './FirstClickContext';
import { useTokenAccounts } from './TokenHolders';
import React, { useRef, useEffect, useState } from 'react';

const LandingPage = () => {
    const { firstClickDetected } = useFirstClick();
    const { tokenAccounts } = useTokenAccounts();
    const sliderRef = useRef(null); // Ref for the slider container
    const [translatePercent, setTranslatePercent] = useState(0);
    const [isHoveredInfo, setIsHoveredInfo] = useState(false);
    const [isHoveredBobby, setIsHoveredBobby] = useState(false);
    const bobbyText = ["Help me to get my PICKLEISGOOD injection!", "PICKLEISGOOD ARGH!"]
    const currentHoldersNumb = tokenAccounts.length-9 < 0 ? 0 : tokenAccounts.length-9;

    useEffect(() => {
        const calculateTranslation = () => {
            const sliderWidth = sliderRef.current.offsetWidth;
            const totalHolders = 10000;
            let currentHolders = currentHoldersNumb > totalHolders ? 10000 : (currentHoldersNumb);
            const offset = isMobile? 110: 130;
            const lerp = 0 + ((sliderWidth-offset) - 0)*(currentHolders/totalHolders);

            setTranslatePercent(lerp);
        };

        calculateTranslation();

        window.addEventListener('resize', calculateTranslation);
        return () => window.removeEventListener('resize', calculateTranslation);
    }, [tokenAccounts.length]);

    return (
        <>
            <main>
                <div id='background-landingPage'>
                    <img src={landingPageBackground} className='lp-desktop-bg no-select'></img>
                    <img src={landingPageBackgroundMobile} className='lp-mobile-bg no-select'></img>
                    <h2 style={{pointerEvents: firstClickDetected ? 'auto' : 'none'}}>The First meme coin to Build a Community-Based Animated series</h2>
                    <div id='social-links' style={{pointerEvents: isMobile ? (firstClickDetected ? 'auto' : 'none') : 'auto'}}>
                        <a className= "btn" href="https://twitter.com/PICKLEISGOODSOL" target="_blank">
                            <img src={twitterLogo} className='no-select'></img>
                        </a>
                        <a className= "btn" href="https://t.me/pickleisgood" target="_blank">
                            <img src= {telegramLogo} className='no-select'></img>
                        </a>
                        <a className= "btn" href="https://dexscreener.com/solana/FKaUMenRcVWhJ6Qa7yRxcSqkqfg5Bzok95V2AUdjCfTj" target="_blank">
                            <img src= {dexscreenerLogo} className= 'no-select'></img>
                        </a>
                    </div>
                </div>

                <div id='container-holders' style={{pointerEvents: isMobile ? (firstClickDetected ? 'auto' : 'none') : 'auto'}}>
                    <div id='slider-holders' ref={sliderRef}>
                        <p>Number of Holders: {currentHoldersNumb}/10000</p>
                        <i className="fa-solid fa-circle-info" style={{zIndex: '20'}} onMouseEnter={() => setIsHoveredInfo(true)} onMouseLeave={() => setIsHoveredInfo(false)}></i>
                        <img src={bobby} id='bobby' className='no-select' onMouseEnter={()=> setIsHoveredBobby(true)} 
                        onMouseLeave={()=> setIsHoveredBobby(false)}></img>
                        <img src={miniPickle} id='mini-pickle' style={{left: `${translatePercent}px`}} className='no-select'></img>
                    </div>
                    <div id='water-effect' style={{width: `${(currentHoldersNumb/10000)*100}%`}}></div>
                    <div id='holders-info' style={{display: isHoveredInfo ? 'block' : 'none'}}>
                        <p><strong>Phase 1</strong></p>
                        <p>Let's reach 10,000 holders and that guy in the green shirt will shove that pickle up his ass! He'll cum an <strong>airdrop</strong></p>
                    </div>
                    <div id='bobby-text' style={{display: isHoveredBobby ? 'block' : 'none'}}>
                        <p><strong>Green shirt guy</strong></p>
                        <p>{currentHoldersNumb >= 10000 ? bobbyText[1] : bobbyText[0]}</p>
                    </div>
                </div>

                <h1 style={{pointerEvents: (firstClickDetected ? 'auto' : 'none')}}>Brace for an epic adventure as your favorite memes come to life in an animated series, soon</h1>
            </main>
        </>
    )
}


export default LandingPage;