import { Connection, PublicKey } from '@solana/web3.js';
import React, { createContext, useContext,useEffect, useState } from 'react';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

const TokenAccountsContext = createContext();
export const useTokenAccounts = () => useContext(TokenAccountsContext);



export const TokenAccountsProvider = ({ children, mintAddress }) => {
    const [tokenAccounts, setTokenAccounts] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const fetchTokenAccounts = async () => {
            setIsLoading(true);
            const rpcUrl = "https://solana-mainnet.core.chainstack.com/bb35a25581a93af17218947385fccc01";
            const connection = new Connection(rpcUrl, "confirmed");

            try {
                const mintPublicKey = new PublicKey(mintAddress);
                const memcmpFilter = {
                    memcmp: {
                        offset: 0,
                        bytes: mintPublicKey.toBase58(),
                    },
                };

                const filters = [
                    { dataSize: 165 },
                    memcmpFilter,
                ];

                const programAccounts = await connection.getParsedProgramAccounts(
                    TOKEN_PROGRAM_ID,
                    { filters }
                );

                const accounts = programAccounts.map(({ pubkey, account }) => ({
                    publicKey: pubkey.toString(),
                    accountData: account.data.parsed.info,
                }));

                setTokenAccounts(accounts);
            } catch (error) {
                console.error("Failed to fetch token accounts:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (mintAddress) {
            fetchTokenAccounts();
        }
    }, [mintAddress]);

    return (
        <TokenAccountsContext.Provider value={{ tokenAccounts, isLoading, error }}>
            {children}
        </TokenAccountsContext.Provider>
    );
};