import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Components/Navbar.js';
import LandingPage from './Components/LandingPage.js';
import Story from './Components/Story.js';
import Instructions from './Components/Instructions.js';
import Holders from './Components/Holders.js';
import Tokenomics from './Components/Tokenomics.js';
import Footer from './Components/Footer.js';
import Fluid from 'webgl-fluid';
import { FirstClickProvider } from './Components/FirstClickContext.js';
import ScrollSlider from './Components/ScrollSlider.js';
import { TokenAccountsProvider } from './Components/TokenHolders.js';

function App() {
  const canvas = useRef(null)
  const mintAddress = "FKaUMenRcVWhJ6Qa7yRxcSqkqfg5Bzok95V2AUdjCfTj";

  useEffect(function () {
    var config = {
      SIM_RESOLUTION: 128,
      DYE_RESOLUTION: 256,
      CAPTURE_RESOLUTION: 512,
      DENSITY_DISSIPATION: 1,
      VELOCITY_DISSIPATION: 0.2,
      PRESSURE: 0.8,
      PRESSURE_ITERATIONS: 20,
      CURL: 30,
      SPLAT_RADIUS: 0.08,
      SPLAT_FORCE: 6000,
      SHADING: true,
      COLORFUL: true,
      COLOR_UPDATE_SPEED: 10,
      PAUSED: false,
      BACK_COLOR: { r: 0, g: 0, b: 0 },
      TRANSPARENT: false,
      BLOOM: true,
      BLOOM_ITERATIONS: 8,
      BLOOM_RESOLUTION: 256,
      BLOOM_INTENSITY: 0.17,
      BLOOM_THRESHOLD: 1,
      BLOOM_SOFT_KNEE: 0.7,
      SUNRAYS: true,
      SUNRAYS_RESOLUTION: 196,
      SUNRAYS_WEIGHT: 0.8,
  }
    let c = canvas.current
    Fluid(c, config )
  }, [])

  return (
    <div className="App">
      <canvas ref={canvas} style={{width: '100%', height: '100%', position: 'absolute'}}></canvas>
      <FirstClickProvider>
        <Navbar/>
        <TokenAccountsProvider mintAddress={mintAddress}>
          <LandingPage/>
        </TokenAccountsProvider>
      </FirstClickProvider>
      <Story/>
      <Instructions/>
      <Holders/>
      <Tokenomics/>
      <Footer/>
    </div>
  );
}

export default App;
